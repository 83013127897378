import React from "react"
import * as R from "ramda"
import { graphql, Link } from "gatsby"
import { useMicroCopyFi } from "../hooks/microCopy"
import GetMicroCopy from "../shared/getMicroCopy"
import Backgroundimage from "gatsby-background-image"
import { GatsbyImage } from "gatsby-plugin-image"
import LayoutController from "../components/layoutController"

import * as moment from "moment"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import NavigateBefore from "@material-ui/icons/NavigateBefore"
import NavigateNext from "@material-ui/icons/NavigateNext"

import Seo from "../components/seo"
import SocialShare from "../components/socialShare"
import Prose from "../components/prose"

import * as styles from "./pressReleasePage.module.scss"

const PressReleasePage = ({ data, pageContext }) => {
  const microCopyTexts = useMicroCopyFi
  // UI texts
  const labelPressReleases = GetMicroCopy(microCopyTexts, "yleinenTiedotteet")
  const pageContent = R.path(["contentfulContent"], data)

  const title = R.path(["title"], pageContent)
  const mainImage = R.path(["mainImage", "gatsbyImageData"], pageContent)
  const mainImageSEO = R.path(["mainImage", "file", "url"], pageContent)

  const releaseDate = R.path(["releaseDate"], pageContent)
  const keywords = R.path(["keywords"], pageContent)

  const releaseDateString = moment(releaseDate, "YYYY-MM-DD").format(
    "DD.MM.YYYY"
  )

  // Youtube embedly help
  const regexEmbedlyText = new RegExp("Embedded content: (.*)", "g")
  const regexYouTube =
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/g
  let textContent = R.path(["contentText", "contentText"], pageContent)
  if (textContent) {
    textContent = textContent
      .replace(regexEmbedlyText, "")
      .replace(regexYouTube, "https://youtu.be/$1?rel=0")
  }

  const contentTextShort = R.path(["contentTextShort"], pageContent)

  return (
    <LayoutController language="fi"> 
    <Box>
      <Seo
        seoTitle={title}
        seoDesc={contentTextShort}
        url={typeof window !== "undefined" ? window.location.href : ""}
        image={mainImageSEO}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "35px",
          padding: "0 20px",
        }}
      >
        <Box style={{ maxWidth: "1200px", width: "100%" }}>
          <Box className={styles.titleContainer}>
            <Grid container>
              <Grid item xs={12}>
                <p className="categoryText">
                  {labelPressReleases}
                  {keywords && " - "}
                  {keywords && keywords.join(", ")}
                </p>
                <Grid container>
                  <Grid item xs={12} sm={12} lg={9}>
                    <h1 style={{ paddingRight: "20px" }}>{title}</h1>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={3}
                    style={{ display: "inline-flex", alignItems: "flex-end" }}
                  >
                    <SocialShare />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <p>{releaseDateString}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box className={styles.mainImageContainer}>
            <GatsbyImage image={mainImage} style={{ maxHeight: "656px" }} />
          </Box>
          <Grid container direction="row">
            <Grid item xs={12} lg={2}></Grid>
            <Grid item xs={12} lg={8}>
              <div style={{ marginBottom: "83px" }}>
                <Prose content={textContent} />
              </div>
            </Grid>
            <Grid item xs={12} lg={2}></Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
    </LayoutController>
  )
}

export default PressReleasePage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query pressreleasePageQuery($slug: String) {
    contentfulContent: contentfulTiedotesivu(slug: { eq: $slug }) {
      id
      title
      keywords
      releaseDate
      slug
      mainImage {
        id
        file {
          url
        }
        gatsbyImageData(quality: 60, layout: CONSTRAINED)
      }
      contentText {
        contentText
      }
      contentTextShort
    }
  }
`
